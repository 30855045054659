<template>
  <b-table-simple class="table" responsive borderless fixed>
    <thead>
      <tr>
        <th>Layout</th>
        <th>Descrição</th>
        <th>Valor total</th>
        <th v-if="withActions || withView"></th>
      </tr>
    </thead>
    <tbody class="w-100" v-if="!layouts?.length && !loading">
      <tr class="p-5 m-5">
        <td colspan="4" class="no-content m-5 p-5">
          <h6>{{ message }}</h6>
        </td>
      </tr>
    </tbody>
    <tbody class="w-100" v-else>
      <tr v-for="(layout, index) in layouts" :key="index">
        <td class="name-col">
          <div class="layout-td">
            <Check
                v-if="withCheckbox"
                v-model="layout.selected"
              />
              <Ellipsis>{{ layout?.name }}</Ellipsis>
          </div>
        </td>
        <td class="name-col">
          <Ellipsis>
            {{ layout?.description ?? '-' }}
          </Ellipsis>
        </td>
        <td>{{ getTotalValue(layout.expenses) }}</td>
        <td v-show="withActions || withView">
          <b-row class="justify-content-end" >
            <Eye 
              v-if="withView"
              class="icon mr-2 eye" 
              @click="action('view', index)" 
            />
            <div v-if="withActions">
              <Copy 
                class="icon copy" 
                @click="action('copy', index)" 
                v-b-tooltip.hover title="Duplicar layout"  
              />
              <Edit 
                class="icon ml-2 mr-2" 
                @click="action('edit', index)" 
                v-b-tooltip.hover title="Ver e editar detalhes do layout"
              />
            </div>
            <Trash 
              v-if="withDelete" 
              class="icon trash" 
              v-b-tooltip.hover title="Excluir layout"
              @click="action('delete', index)" 
            />
          </b-row>
        </td>
      </tr>
      <tr v-if="loading">
        <td colspan="5">
          <b-skeleton type="input" />
        </td>
      </tr>
    </tbody>
  </b-table-simple>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  name: 'LayoutsTable',
  components: {
    Ellipsis: () => import('@/components/General/Ellipsis'),
    Copy: () => import('@/assets/icons/copy.svg'),
    Edit: () => import('@/assets/icons/edit.svg'),
    Trash: () => import('@/assets/icons/trash.svg'),
    Eye: () => import('@/assets/icons/eye.svg'),
    Check: () => import('@/components/General/Check'),
  },
  props: {
    layouts: {
      type: Array,
      default: () => [],
    },
    message: {
      type: String,
      default: 'Configure um novo layout para facilitar o preenchimento das guias TISS',
    },
    withCheckbox: Boolean,
    withActions: Boolean,
    withView: Boolean,
    withDelete: Boolean,
    loading: Boolean,
  },
  methods: {
    action(action, index) {
      this.$emit(action, index)
    },
    getTotalValue(expenses) {
      const total = expenses.reduce((acc, expense) => acc + expense.quantity * expense.unitary_value, 0)
      return parseNumberToMoney(total)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.name-col {
  max-width: 20vw;
}
.icon {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.trash {
  stroke: var(--states-red-soft);
}

.copy {
  transform: scale(.85);
}

.no-content {
  text-align: center;
}

.eye {
  fill: var(--neutral-500);
}
.layout-td {
  display: flex;
  align-items: center;
}
</style>
